// export const API_URL = "https://apothekeadmin.24livehost.com/api";   //demo
// export const FRONT_URL = "https://apotheke.24livehost.com";   //demo
export const API_URL = 'https://admin.pharmapremium.de/api';  //live
export const FRONT_URL = "https://pharmapremium.de";  //live

// Sandbox Klarna
// export const KlarnaDetails = {
//   apiKey: "94a4f81c-b7e2-4886-b7a6-71839c439ffa",
//   apiSecret:
//     "klarna_test_api_WlZhWmIxWlByL2xiM1o5cld2VjhoZUk4RTZBdVFEJEIsOTRhNGY4MWMtYjdlMi00ODg2LWI3YTYtNzE4MzljNDM5ZmZhLDEsczhNaDgwMnJlbk40NWp3WUtwc1d5YVFZQnI4dzRjYmUxdUV4RWFoTlpEMD0",
//   apiURL: "https://api.playground.klarna.com",
//   scriptUrl: 'https://cdn.klarna.com/checkout.js'
// };

// Live Klarna
export const KlarnaDetails = {
  apiKey: "f9e85234-5790-4236-89ef-ed4f1e3062eb",
  apiSecret:
    "klarna_live_api_YlQ1WFF5KVNyRmhNWWI_bHpRZUJFKlg5R2ZJckRGJVUsZjllODUyMzQtNTc5MC00MjM2LTg5ZWYtZWQ0ZjFlMzA2MmViLDEsM0ZPNE43QVRMNUFjN3NWVXlMK1AweU1UVGlVV3hLRFcvRW45dkhkUlJqbz0",
  scriptUrl: 'https://cdn.klarna.com/checkout.js',
  apiURL: "https://api.klarna.com",
};

// Sandbox Paypal
// export const paypalDetails = {
//   clientId: "AXa_OWYfO1hFf2fEgxsWUlY1dMzHyADimpVgKmFAksp0yqR7mxMb-gILTTXebDXh75cz69RqFWI0VM0o",
//   clientSecret: "EOYswH_9yLmGi58uuMIDdIwIbrnrGYzLxCMmKVpal97ZdfUCLE_e6OSNcNNuvvNoX5ukiylcrjOd-1jE",
//   baseUrl: 'https://api-m.sandbox.paypal.com'  
// };

// Live Paypal
export const paypalDetails = {
  clientId: "ATloy6qpZiwBl-0HanwVYS7vFkUKKo59m6rvmjg4g7LqHnwGyOGrlqTYkyMHpc28H8IADBRkcMGFFTvT",
  // clientSecret: "EGOYwqLyVMdBJaiZfFE8Bax93Qpe4Wie6SXFaaG0QY9P6HH1cjcqXtHcBl37ZF716Qc0qaC6Hdp2DYXy",
  clientSecret: "EFOXcfjnkffTONkl10-Teh3aAocezkQiGNenFbrT7k4VsC535W6QHbLHh2s_3jRSLjhRYpJ0VVexAPcA",
  baseUrl: 'https://api-m.paypal.com'  
};