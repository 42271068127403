import { Icon } from "@iconify/react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

function Menu(props) {
  const { open, handleClose } = props;
  const navigate = useNavigate()
  const { categories } = useSelector((state) => state?.common);

  const toggleClass = (id) => {
    const elem = document.getElementById(id);
    elem.classList.toggle("showSubMenu");
  };

  const handleClick = (e, url) => {
    e.preventDefault()
    navigate(url)
    handleClose(false)
  }

  return (
    <Drawer
      className="menuDrawer_outer"
      open={open}
      onClose={() => handleClose(false)}
    >
      <Box className="menuDrawer" role="presentation">
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Typography>
            <a href="/">
              <img
                src="/images/apotheke-premium-logo.webp"
                alt=""
                width={200} height="auto"
              />
            </a>
          </Typography>
          <IconButton
            className="menuCloseBtn"
            onClick={() => handleClose(false)}
          >
            <Icon icon="ic:sharp-close" />
          </IconButton>
        </Box>
        <List sx={{ p: "1rem 0px 0px 10px" }}>
          {categories?.data?.map((category, index) => (
            <Fragment key={`menu${index}`}>
              <ListItem disablePadding sx={{ display: "block" }}>
                {category?.children?.length > 0 ? (
                  <>
                    <ListItemButton
                      sx={{ padding: "0", backgroundColor: "#fff !important" }}
                    >
                      <Link className="w100 dflex">
                        <ListItemText
                          className="menuTxt"
                          onClick={() => {
                            toggleClass(category?.id);
                          }}
                          primary={category?.name}
                          sx={{ margin: "0", color: "#0f3f83" }}
                        />
                        <ListItemIcon
                          className="menuPlusIcon"
                          onClick={() => {
                            toggleClass(category?.id);
                          }}
                        >
                          <Icon icon="mdi:plus" fontSize={30} color="#0f3f83" />
                        </ListItemIcon>
                      </Link>
                    </ListItemButton>
                    <List id={category?.id}>
                      {category?.children?.map((subCat, index) => (
                        <ListItem key={`subCat${index}`}>
                          <Link
                            onClick={(e)=>handleClick(e,`/product-category/${category?.slug}/${subCat?.slug}`)}
                          >
                            <Icon icon="weui:arrow-outlined" fontSize={24} />
                            {subCat?.name}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  <ListItemButton
                    sx={{ padding: "0", backgroundColor: "#fff !important" }}
                  >
                    <Link onClick={(e)=>handleClick(e,`/product-category/${category?.slug}/${category?.id}`)} className="w100 dflex">
                      <ListItemText
                        className="menuTxt"
                       
                        primary={category?.name}
                        sx={{ margin: "0", color: "#0f3f83" }}
                      />
                    </Link>
                  </ListItemButton>
                )}
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default Menu;
